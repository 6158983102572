/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useCategory } from "../../Category";
import { v4 as uuidv4 } from "uuid";
import TechRequestsApi from "../../../../Apis/TechRequestsApi";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSubCategory } from "../../SubCategoryContext";
import AuthApi from "../../../../Apis/AuthApi";
import TokenManager from "../../../../Apis/TokenManager";
import { useTranslation } from "react-i18next";

const requestPart = () => {
  const { t } = useTranslation();
  const claims = TokenManager.getClaims();
  const history = useHistory();
  const [user, setUser] = useState();
  const { categories, getCategoryById } = useCategory();
  const { subCategories, fetchSubCategories } = useSubCategory();
  const [loading, setLoading] = useState(false);
  const [requestTitle, setRequestTitle] = useState();
  const [selectedCat, setSelectedCat] = useState(0);
  const [selectedSubCat, setSelectedSubCat] = useState(0);
  const [imagePreview, setImagePreview] = useState([]);
  const [images, setImages] = useState([]);
  const [refrenceLink, setRefrenceLink] = useState(null);
  const [price, setPrice] = useState();
  const [desc, setDesc] = useState();

  useEffect(() => {
    const getSubCategories = async () => {
      if (selectedCat) {
        await fetchSubCategories(selectedCat);
      }
    };
    getSubCategories();
  }, [selectedCat]);

  const getUserDetails = async () => {
    setLoading(true);
    const response = await AuthApi.getUser(claims.sub);
    if (response.success) {
      setLoading(false);
      setUser(response.data);
    } else {
      setLoading(false);
      toast.error("something went wrong");
    }
  };

  const handleImageUpload = (files) => {
    const newImages = [...imagePreview];
    const newImagesBob = [];
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const blob = new Blob([file.slice(0, file.size)], { type: file.type });
        newImages.push(e.target.result);
        newImagesBob.push(blob);
        setImagePreview(newImages);
        setImages(newImagesBob);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (index) => {
    const newImages = [...imagePreview];
    const newImagesBob = [];
    newImages.splice(index, 1);
    newImagesBob.splice(index, 1);
    setImagePreview(newImages);
    handleImageUpload(newImagesBob);
  };

  const handleRequestSubmit = async () => {
    if (selectedCat === 0) {
      toast.error("please choose category value");
      return;
    }
    if (selectedSubCat === 0) {
      toast.error("please choose Sub Category value");
      return;
    }

    if (requestTitle === "" || images.length === 0 || desc === "") {
      toast.error("please fill in required fields");
      return;
    }

    setLoading(true);
    let fullDescription = desc;
    if (refrenceLink) {
      fullDescription += `\n\nRef: ${refrenceLink}`;
    }

    const formData = new FormData();
    formData.append("CategoryId", selectedCat);
    formData.append("SubCategoryId", selectedSubCat);
    formData.append("Title", requestTitle);
    formData.append("Price", price);
    formData.append("Description", fullDescription);
    images?.forEach((image) => {
      formData.append("Images", image, uuidv4() + ".png");
    });
    try {
      const response = await TechRequestsApi.addTechRequests(formData);
      if (response.success) {
        setLoading(false);
        toast.success("Your request has been puplished!");
        history.push("/requests");
      } else {
        setLoading(false);
        toast.error("something went wrong");
      }
    } catch (error) {
      setLoading(false);
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    getUserDetails();
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      {/* Breadcrumb */}
      <div className="bread-crumb-bar">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <div className="breadcrumb-list">
                <h3>{t('requestPart.requestPart')}</h3>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/index">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                    {t('requestPart.requestPart')}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Page Content */}
      {user?.status !== "Approved" ? (
        <p>{t('requestPart.verifyAccount')}</p>
      ) : (
        <>
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="select-project mb-4">
                    <form>
                      <div className="title-box widget-box">
                        {/* Project Title */}
                        <div className="title-content">
                          <div className="title-detail">
                            <h3>{t('requestPart.partName')}</h3>
                            <div className="form-group mb-0">
                              <input
                                type="text"
                                value={requestTitle}
                                onChange={(e) =>
                                  setRequestTitle(e.target.value)
                                }
                                className="form-control"
                                placeholder="Lenovo ThinkPad X1 Carbon Motherboard"
                              />
                            </div>
                          </div>
                        </div>
                        {/* /Project Title */}
                        {/* Category Content */}
                        <div className="title-content">
                          <div className="title-detail">
                            <h3>{t('requestPart.categoryType')}</h3>
                            <div className="form-group mb-0">
                              <select
                                className="form-control select"
                                value={selectedCat}
                                onChange={(e) => setSelectedCat(e.target.value)}
                              >
                                <option value={0}>{t('requestPart.select')}</option>
                                {categories.map((cat) => (
                                  <option key={cat.id} value={cat.id}>
                                    {cat.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="title-content">
                          <div className="title-detail">
                            <h3>{t('requestPart.subCategory')}</h3>
                            <div className="form-group mb-0">
                              <select
                                className="form-control select"
                                value={selectedSubCat}
                                onChange={(e) =>
                                  setSelectedSubCat(e.target.value)
                                }
                              >
                                <option value="">{t('requestPart.select')}</option>
                                {subCategories?.map((subCat) => (
                                  <option key={subCat.id} value={subCat.id}>
                                    {subCat.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* /Category Content */}
                        {/* Price Content */}
                        {/* <div className="title-content">
                      <div className="title-detail">
                        <h3>Pricing Type</h3>
                        <div className="form-group price-cont mb-0" id="price_type">
                          <select name="price" className="form-control select">
                            <option value={0}>Select</option>
                            <option value={1}>Fixed Budget Price</option>
                            <option value={2}>Hourly Pricing</option>
                            <option value={3}>Biding Price</option>
                          </select>
                        </div>
                        <div
                          className="form-group mt-3"
                          id="price_id"
                          style={{ display: "none" }}
                        >
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-white dropdown-toggle"
                                data-bs-toggle="dropdown"
                              >
                                $
                              </button>
                              <div className="dropdown-menu">
                                <Link className="dropdown-item" to="#">
                                  Dollars
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Euro
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Bitcoin
                                </Link>
                              </div>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={20.0}
                            />
                          </div>
                        </div>
                        <div
                          className="form-group mt-3"
                          id="hour_id"
                          style={{ display: "none" }}
                        >
                          <div className="row">
                            <div className="col-md-6 mb-2">
                              <div className="input-group form-inline">
                                <div className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn btn-white dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                  >
                                    $
                                  </button>
                                  <div className="dropdown-menu">
                                    <Link className="dropdown-item" to="#">
                                      Dollars
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Euro
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Bitcoin
                                    </Link>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control mr-2"
                                  placeholder={20.0}
                                />{" "}
                                <label> / hr</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-group form-inline">
                                <label>For </label>{" "}
                                <input
                                  type="text"
                                  className="form-control ml-2"
                                  placeholder=" ( eg: 2 Weeks)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                        {/* /Price Content */}
                        {/* Skills Content */}
                        {/* <div className="title-content">
                      <div className="title-detail">
                        <h3>Desired areas of expertise </h3>
                        <div className="form-group mb-0">
                          <input
                            type="text"
                            data-role="tagsinput"
                            className="input-tags form-control"
                            name="services"
                            defaultValue="Web Design"
                            id="services"
                            placeholder="UX, UI, App Design, Wireframing, Branding"
                          />
                          <p className="text-muted mb-0">
                            Enter skills for needed for project
                          </p>
                        </div>
                      </div>
                    </div> */}
                        {/* /Skills Content */}
                        {/* Project Period Content */}
                        {/* <div className="title-content">
                      <div className="title-detail">
                        <h3>Period of Project</h3>
                        <div className="form-group mb-0" id="pro_period">
                          <div className="radio">
                            <label className="custom_radio">
                              <input
                                type="radio"
                                defaultValue="period"
                                name="period"
                              />
                              <span className="checkmark" /> Start immediately after
                              the candidate is selected
                            </label>
                          </div>
                          <div className="radio">
                            <label className="custom_radio">
                              <input
                                type="radio"
                                defaultValue="job"
                                name="period"
                                defaultChecked
                              />
                              <span className="checkmark" /> Job will Start On
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="filter-widget mb-0" id="period_date">
                              <div className="cal-icon">
                                <input
                                  type="text"
                                  className="form-control datetimepicker"
                                  placeholder="Select Date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                        {/* /Project Period Content */}
                        {/* /Add Document */}
                        <div className="title-content">
                          <div className="title-detail">
                            <h3>{t('requestPart.addImages')}</h3>
                            <div className="custom-file">
                              <input
                                type="file"
                                multiple
                                onChange={(e) =>
                                  handleImageUpload(e.target.files)
                                }
                                className="custom-file-input"
                              />
                              <label className="custom-file-label" />
                            </div>
                            <p className="mb-0">
                            {t('requestPart.imageSizeNotice')}
                            </p>
                          </div>
                          <div style={{ display: "flex", margin: "10px 0" }}>
                            {imagePreview?.map((image, ind) => (
                              <>
                                <div className="profile-img mb20  position-relative">
                                  <img
                                    className=" bdrs12 cover"
                                    src={image}
                                    style={{
                                      height: "200px",
                                      width: "200px",
                                      margin: "0 10px",
                                    }}
                                    alt={`Uploaded Image ${ind + 1}`}
                                  />
                                  <button
                                    style={{
                                      border: "none",
                                      position: "absolute",
                                      left: "10px",
                                    }}
                                    className="tag-del"
                                    title="Delete Image"
                                    onClick={() => handleDelete(ind)}
                                    type="button"
                                    data-tooltip-id={`delete-${ind}`}
                                  >
                                    <span className="fas fa-trash-can" />
                                  </button>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                        {/* /Add Document */}

                        {/* /Add Tags */}
                        {/* <div className="title-content">
                      <div className="title-detail">
                        <h3>Add Tags *</h3>
                        <div className="links-info">
                          <div className="row form-row links-cont">
                            <div className="col-12 col-md-11">
                              <div className="form-group mb-0">
                                <input
                                  type="text"
                                  multiple
                                  className="form-control"
                                />
                                <p className="mb-0">Add maximum 3 tags</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                        {/* /Add Tags */}

                        {/* Add Links */}
                        <div className="title-content">
                          <div className="title-detail">
                            <h3>{t('requestPart.addLinks')}</h3>
                            <div className="links-info">
                              <div className="row form-row links-cont">
                                <div className="col-12 col-md-11">
                                  <div className="form-group mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={price}
                                      onChange={(e) =>
                                        setPrice(e.target.value)
                                      }
                                    />
                                    {/* <p className="mb-0">
                                      Add Reference links if any
                                    </p> */}
                                  </div>
                                </div>
                                {/* <div className="col-12 col-md-1">
                              <Link
                                to=""
                                className="btn add-links"
                              >
                                <i className="fas fa-plus" />
                              </Link>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /Add Links */}
                        {/* Project Title */}
                        <div className="title-content pb-0">
                          <div className="title-detail">
                            <h3>{t('requestPart.writeDescription')}</h3>
                            <div
                              className="form-group mb-0"
                              style={{ border: "1px solid #8080805c" }}
                            >
                              {/* <Editor
                            // eslint-disable-next-line no-undef
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                          /> */}
                              <textarea
                                className="form-control summernote"
                                rows={5}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        {/* /Project Title */}
                        <div className="row">
                          <div className="col-md-12 text-end">
                            <div className="btn-item">
                              <button
                                type="button"
                                className="btn next-btn"
                                onClick={handleRequestSubmit}
                                disabled={loading}
                              >
                                {loading ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  <>{t('requestPart.submit')}</>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Project Title */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* /Page Content */}
    </>
  );
};
export default requestPart;
