/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
// Import Images
import {
  company_img2,
  logo_icon_01,
  find,
  requests,
  posts,
  Avatar_1,
} from "../imagepath";
import LoadingSpinner from "../../../LoadingSpinner";
import TokenManager from "../../../Apis/TokenManager";
import { useCategory } from "../Category";
import AuthApi from "../../../Apis/AuthApi";
import TechOffers from "../../../Apis/TechOffersApi";
import TechRequestsApi from "../../../Apis/TechRequestsApi";
import { toast } from "react-toastify";
import SubCategoryApi from "../../../Apis/SubCategoryApi";
import getDateTime from "../../../getDateTime";
import { useTranslation } from "react-i18next";

const UserDetails = () => {
  const claims = TokenManager.getClaims();
  const [activeTab, setActiveTab] = useState("overview");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [post, setPost] = useState();
  const [request, setRequest] = useState();
  const [responseTechOffer, setResponseTechOffer] = useState();
  const [responseTechRequest, setResponseTechRequests] = useState();
  const { getCategoryById } = useCategory();
  const [subCategories, setSubCategories] = useState({});
  const [userCache, setUserCache] = useState({});
  const { t } = useTranslation(); 
  const inputRef = useRef(null);

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };
  const copyToClipboard = () => {
    const input = inputRef.current;
    input.select(); // Select the text
    input.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand("copy"); // Copy the selected text to clipboard
    alert("Copied to clipboard: " + input.value); // Optional: alert the user
  };

  const getUserById = async () => {
    setLoading(true);
    const response = await AuthApi.getUser(claims.sub);
    if (response.success) {
      setUser(response.data);
      setLoading(false);
    } else {
      toast.error("error while getting user details");
      setLoading(false);
    }
  };
  const getUniqueUserById = async (id) => {
    if (userCache[id]) {
      return userCache[id];
    }

    const response = await AuthApi.getUser(id);
    if (response.success) {
      return response.data;
    } else {
      toast.error("something went wrong");
      return null;
    }
  };
  const fetchUsers = async (data) => {
    const uniqueUserIds = [...new Set(data?.map((item) => item.userId))];
    const userPromises = uniqueUserIds.map((id) => getUniqueUserById(id));
    const users = await Promise.all(userPromises);

    const userCacheData = users.reduce((acc, user) => {
      if (user) {
        acc[user.id] = user;
      }
      return acc;
    }, {});
    console.log("myResponses", data);
    setUserCache(userCacheData);
  };
  const getUserTechOffers = async () => {
    handleChangeTab("bids");
    if (claims) {
      setLoading(true);
      const response = await TechOffers.getTechOfferByUserId(claims.sub);
      if (response.success) {
        setPost(response.data);
        await getUserTechOffersResponses();
        setLoading(false);
      } else {
        toast.error("error while getting user posts");
        setLoading(false);
      }
    } else {
      toast.error("error while getting user posts");
    }
  };

  const getUserTechRequests = async () => {
    handleChangeTab("jobs");
    if (claims) {
      setLoading(true);
      const response = await TechRequestsApi.getTechRequestByUserID(claims.sub);
      if (response.success) {
        setRequest(response.data);
        await getUserTechRequestsResponses();
        setLoading(false);
      } else {
        toast.error("error while getting user requests");
        setLoading(false);
      }
    } else {
      toast.error("error while getting user requests");
    }
  };
  const fetchSubCategory = async (id) => {
    if (!subCategories[id]) {
      const response = await SubCategoryApi.getSubCategoryById(id);
      if (response.success) {
        setSubCategories((prev) => ({
          ...prev,
          [id]: response.data.name,
        }));
      }
    }
  };

  const getUserTechOffersResponses = async () => {
    if (claims) {
      setLoading(true);
      try {
        const response = await TechOffers.getAllTechOffers();
        if (response.success) {
          const myResponses = response.data.filter((offer) => {
            return offer.comments?.some(
              (comment) => comment.userId === claims.sub
            );
          });
          setResponseTechOffer(myResponses);
        } else {
          toast.error("Error while getting user posts");
        }
      } catch (error) {
        toast.error("Error while getting user posts");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Error while getting user posts");
    }
  };

  const getUserTechRequestsResponses = async () => {
    if (claims) {
      setLoading(true);
      try {
        const response = await TechRequestsApi.getAllTechRequests();
        if (response.success) {
          const myResponses = response.data.filter((offer) => {
            return offer.comments?.some(
              (comment) => comment.userId === claims.sub
            );
          });
          setResponseTechRequests(myResponses);
        } else {
          toast.error("Error while getting user requests");
        }
      } catch (error) {
        toast.error("Error while getting user requests");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Error while getting user requests");
    }
  };

  const deleteTechOffer = async (id) => {
    const response = await TechOffers.deleteTechOffer(id);
    if (response.success) {
      toast.success("post deleted successfully");
      getUserTechOffers();
    }
  };

  const deleteTechRequest = async (id) => {
    const response = await TechRequestsApi.deleteTechRequests(id);
    if (response.success) {
      toast.success("request deleted successfully");
      getUserTechRequests();
    }
  };

  useEffect(() => {
    if (request) {
      request.forEach((item) => {
        if (item.subCategoryId) {
          fetchSubCategory(item.subCategoryId);
        }
      });
    }
    if (post) {
      post.forEach((item) => {
        if (item.subCategoryId) {
          fetchSubCategory(item.subCategoryId);
        }
      });
    }
  }, [request, posts]);

  useEffect(() => {
    fetchUsers(responseTechOffer);
  }, [responseTechOffer]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getUserById();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-end">
              {/* <div className="inner-content">
                      <label className="file-upload image-btn">
                        Change Image <input type="file" />
                      </label>
                    </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* User Profile Details */}
              <div className="profile">
                <div className="profile-box">
                  <div className="provider-widget">
                    <div className="pro-info-left">
                      <div className="provider-img">
                        <img
                          src={user?.image || Avatar_1}
                          alt="User"
                          width={150}
                        />
                        {/* <div className="camera-bg">
                          <i className="fa fa-camera" />
                        </div> */}
                      </div>
                      <div className="profile-info profile-edit-form">
                        <h2 className="profile-title">{user?.businessName}</h2>
                        <div className="pro-text3">
                          {/* <p className="">Since July 2024</p> */}
                          {/* <div>
                                  <Link to="#" className="btn full-btn">
                                    Full time
                                  </Link>
                                </div>  */}
                          <ul className="profile-preword">
                            <li>
                              {/* <img src={Flags_pl} alt="" height={16} />{" "} */}
                              {/* Netherlands Eindhoven Posted 1 hour ago */}
                            </li>
                            {/* <li>
                                    <div className="rating">
                                      <span className="average-rating">4.6</span>
                                      <i className="fas fa-star filled" />
                                      <i className="fas fa-star filled" />
                                      <i className="fas fa-star filled" />
                                      <i className="fas fa-star filled" />
                                      <i className="fas fa-star filled" />
                                    </div>
                                  </li> */}
                          </ul>
                        </div>

                        {/* <div className="pro-new3">
                                <div className="row">
                                  <div className="col-12">
                                    <form>
                                      <div className="form-row">
                                        <div className="form-group col-lg-4">
                                          <label>Professional Headline</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="IOS Developer"
                                          />
                                        </div>
                                        <div className="form-group col-lg-4">
                                          <label>Select Work type</label>
                                          <select className="form-control select">
                                            <option>Select woktype</option>
                                          </select>
                                        </div>
                                        <div className="form-group col-lg-4">
                                          <label>Professional Headline</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="IOS Developer"
                                          />
                                        </div>
                                      </div>
                                      <div className="form-row">
                                        <div className="form-group col-lg-4">
                                          <label>Hourly Rate</label>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">$</span>
                                            </div>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Username"
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                USD / HR
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-4">
                                          <label>Select Country</label>
                                          <select className="form-control select">
                                            <option>-</option>
                                          </select>
                                        </div>
                                        <div className="form-group col-lg-4">
                                          <label />
                                          <div className=" submit-profile-sec">
                                            <Link
                                              to="#"
                                              className="btn btn-primary profile-update-btn"
                                            >
                                              Update
                                            </Link>
                                            <Link
                                              to="#"
                                              className="btn btn-light profile-cancel-btn"
                                            >
                                              Cancel
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div> */}
                      </div>
                    </div>
                    {/* <div className="pro-info-right profile-inf">
                            <Link to="#" id="edit_name" className="sub-title edit-sub-title" >
                              <i className="fa fa-pencil-alt me-1" />
                            </Link>
                          </div> */}
                  </div>
                </div>
                {/* <div className="profile-list">
                        <ul>
                          <li>
                            <span className="cont bg-blue">22</span>
                            <strong className="proj-title"> Completed Projects</strong>
                          </li>
                          <li>
                            <span className="cont bg-red">5</span>
                            <strong className="proj-title"> Ongoing Projects</strong>
                          </li>
                          <li>
                            <span className="cont bg-violet">89%</span>
                            <strong className="proj-title"> Recommended</strong>
                          </li>
                          <li>
                            <span className="cont bg-yellow">12</span>
                            <strong className="proj-title"> Rehired</strong>
                          </li>
                          <li>
                            <span className="cont bg-pink">48</span>
                            <strong className="proj-title"> Clients</strong>
                          </li>
                          <li>
                            <span className="cont bg-navy">5</span>
                            <strong className="proj-title"> Feedbacks</strong>
                          </li>
                        </ul>
                      </div> */}
              </div>
              {/* /User Profile Details */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="pro-view">
                {/* Tab Heading */}
                <nav className="provider-tabs mb-4">
                  <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${activeTab === "overview" && "active"}`}
                        to="#overview"
                        onClick={() => handleChangeTab("overview")}
                        data-bs-toggle="tab"
                      >
                        <img
                          className="img-fluid"
                          alt="User Image"
                          src={find}
                          width={30}
                        />
                        <p className="">{t('userDetails.about')}</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${activeTab === "bids" && "active"}`}
                        to="#bids"
                        onClick={() => getUserTechOffers()}
                        data-bs-toggle="tab"
                      >
                        <img
                          className="img-fluid"
                          alt="User Image"
                          src={posts}
                          width={30}
                        />
                        <p className="">{t('userDetails.posts')}</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${activeTab === "jobs" && "active"}`}
                        to="#jobs"
                        onClick={() => getUserTechRequests()}
                        data-bs-toggle="tab"
                      >
                        <img
                          className="img-fluid"
                          alt="User Image"
                          src={requests}
                          width={30}
                        />
                        <p className="">{t('userDetails.requests')}</p>
                      </Link>
                    </li>
                  </ul>
                </nav>
                {/* /Tab Heading */}
                {/* Tab Details */}
                <div className="tab-content pt-0">
                  {/* Overview Tab Content */}
                  <div
                    role="tabpanel"
                    id="overview"
                    className={`tab-pane fade ${activeTab === "overview" && "show active "}`}
                  >
                    <div className="pro-post widget-box">
                      <div className="row">
                        <div className="col-10">
                        <p className="">{t('userDetails.about')}</p>
                        </div>
                        <div className="col-2 text-end">
                          <Link
                            to="#"
                            id="edit_overview"
                            className="sub-title edit-sub-title"
                          >
                            <i className="fa fa-pencil-alt me-1" />
                          </Link>
                        </div>
                      </div>
                      <div className="pro-overview">
                        <div className="pro-content">
                          <div className="pro-text1">
                            <p>{user?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Overview Tab Content */}
                  {/* Posts Tab Content */}
                  <div
                    role="tabpanel"
                    id="bids"
                    className={`tab-pane fade ${activeTab === "bids" && "show active "}`}
                  >
                    <div className="pro-post project-widget widget-box">
                      <nav className="user-tabs mb-4">
                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              to="#manage_bids"
                              data-bs-toggle="tab"
                            >
                              {t('userDetails.myPosts')}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="#manage_bidders"
                              data-bs-toggle="tab"
                            >
                              {t('userDetails.myResponses')}
                            </Link>
                          </li>
                        </ul>
                      </nav>
                      {/* Posts Tab Details */}
                      <div className="tab-content pt-0">
                        {/* My posts Tab Content */}
                        <div
                          role="tabpanel"
                          id="manage_bids"
                          className="tab-pane fade show active"
                        >
                          <div className="row">
                            {post ? (
                              post?.map((item) => {
                                return (
                                  <>
                                    <div className="col-md-6 col-lg-12 col-xl-6">
                                      <div className="freelance-widget widget-author">
                                        <div className="freelance-content">
                                          <Link
                                            to="#"
                                            className="favourite "
                                            onClick={() =>
                                              deleteTechOffer(item.id)
                                            }
                                          >
                                            <i className="fas fa-trash" />
                                          </Link>
                                          <Link
                                            to={`edit-post/${item.id}`}
                                            className="favourite edit-btn"
                                          >
                                            <i className="fas fa-edit" />
                                          </Link>
                                          <div className="author-heading">
                                            <div className="profile-img">
                                              <Link
                                                to={`post-details/${item?.id}`}
                                                style={{
                                                  height:"100%",
                                                }}
                                              >
                                                <img
                                                  src={user?.image || Avatar_1}
                                                  alt="author"
                                                  style={{
                                                    width:'100%',
                                                    height:"100%",
                                                    borderRadius:"50%"
                                                  }}
                                                />
                                              </Link>
                                            </div>
                                            <div className="profile-name">
                                              <div className="author-location">
                                                {user?.businessName}{" "}
                                                <i className="fas fa-check-circle text-success verified" />
                                              </div>
                                            </div>
                                            <div className="freelance-info">
                                              {/* <div className="freelance-location mb-1">
                                                <i className="fas fa-clock" />{" "}
                                                Posted 29 min ago
                                              </div> */}
                                              <div className="freelance-location">
                                                <i className="fas fa-map-marker-alt me-1" />
                                                {user?.address?.city},{" "}
                                                {user?.address?.country}
                                              </div>
                                            </div>
                                            <div className="freelance-tags">
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {getCategoryById(
                                                    item?.categoryId
                                                  )}
                                                </span>
                                              </Link>
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {subCategories[
                                                    item.subCategoryId
                                                  ] || "Loading..."}
                                                </span>
                                              </Link>
                                            </div>
                                            <Link
                                              to={`post-details/${item?.id}`}
                                              className="freelancers-price"
                                            >
                                              {item?.title}
                                            </Link>
                                          </div>
                                          <div className="counter-stats">
                                            <ul>
                                              <li>
                                                <h3 className="counter-value">
                                                  {getDateTime.getDate(
                                                    item?.createdDate
                                                  )}
                                                </h3>
                                                <h5>{t('userDetails.createdAt')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  {item?.comments?.length}
                                                </h3>
                                                <h5>{t('userDetails.proposals')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  <span className="jobtype">
                                                    Open
                                                  </span>
                                                </h3>
                                                <h5>{t('userDetails.status')}</h5>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <>Loading...</>
                            )}
                          </div>
                        </div>
                        {/* /My posts Tab Content */}
                        {/* My Responses Tab Content */}
                        <div
                          role="tabpanel"
                          id="manage_bidders"
                          className="tab-pane fade"
                        >
                          <div className="row">
                            {responseTechOffer &&
                              responseTechOffer?.map((item) => {
                                const fetchedUser =
                                  userCache[item.userId] || {};

                                return (
                                  <>
                                    <div className="col-md-6 col-lg-12 col-xl-6">
                                      <div className="freelance-widget widget-author">
                                        <div className="freelance-content">
                                          <div className="author-heading">
                                            <div className="profile-img">
                                              <Link
                                                to={`post-details/${item?.id}`}
                                                style={{
                                                  height: "100%",
                                                }}
                                              >
                                                <img
                                                  className="img-fluid"
                                                  src={
                                                    fetchedUser?.image ||
                                                    Avatar_1
                                                  }
                                                  alt="author"
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </Link>
                                            </div>
                                            <div className="profile-name">
                                              <div className="author-location">
                                                {fetchedUser?.businessName}{" "}
                                                <i className="fas fa-check-circle text-success verified" />
                                              </div>
                                            </div>
                                            <div className="freelance-info">
                                              {/* <div className="freelance-location mb-1">
                                                <i className="fas fa-clock" />{" "}
                                                Posted 29 min ago
                                              </div> */}
                                              <div className="freelance-location">
                                                <i className="fas fa-map-marker-alt me-1" />
                                                {fetchedUser?.address?.city},{" "}
                                                {fetchedUser?.address?.country}
                                              </div>
                                            </div>
                                            <div className="freelance-tags">
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {getCategoryById(
                                                    item?.categoryId
                                                  )}
                                                </span>
                                              </Link>
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {subCategories[
                                                    item.subCategoryId
                                                  ] || "Loading..."}
                                                </span>
                                              </Link>
                                            </div>
                                            <div>
                                              {item?.price ? "€" + item.price:""}
                                            </div>
                                            <Link
                                              to={`post-details/${item?.id}`}
                                              className="freelancers-price"
                                            >
                                              {item?.title} {item?.price ? "€" + item.price:""}
                                            </Link>
                                          </div>
                                          <div className="counter-stats">
                                            <ul>
                                              <li>
                                                <h3 className="counter-value">
                                                  {getDateTime.getDate(
                                                    item?.createdDate
                                                  )}
                                                </h3>
                                                <h5>{t('userDetails.createdAt')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  {item?.comments?.length}
                                                </h3>
                                                <h5>{t('userDetails.proposals')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  <span className="jobtype">
                                                    Open
                                                  </span>
                                                </h3>
                                                <h5>{t('userDetails.status')}</h5>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="cart-hover">
                                          <Link
                                            to={`post-details/${item?.id}`}
                                            className="btn-cart"
                                            tabIndex={-1}
                                          >
                                            {t('userDetails.view')}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                        {/* /My Responses  Tab Content */}
                      </div>
                    </div>
                  </div>
                  {/* Posts Tab Content */}
                  {/* Requests Tab Content */}
                  <div
                    role="tabpanel"
                    id="jobs"
                    className={`tab-pane fade ${activeTab === "jobs" && "show active "}`}
                  >
                    <div className="pro-post project-widget widget-box">
                      <nav className="user-tabs mb-4">
                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              to="#manage_jobs"
                              data-bs-toggle="tab"
                            >
                              {t('userDetails.myRequests')}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="#saved_jobs"
                              data-bs-toggle="tab"
                            >
                              {t('userDetails.myResponsesSaved')}
                            </Link>
                          </li>
                        </ul>
                      </nav>
                      <div className="tab-content pt-0">
                        {/*  MyRequests Tab Content */}
                        <div
                          role="tabpanel"
                          id="manage_jobs"
                          className="tab-pane fade show active"
                        >
                          <div className="row">
                            {request &&
                              request?.map((item) => {
                                return (
                                  <>
                                    <div className="col-md-6 col-lg-12 col-xl-6">
                                      <div className="freelance-widget widget-author">
                                        <div className="freelance-content">
                                          <Link
                                            to="#"
                                            className="favourite"
                                            onClick={() =>
                                              deleteTechRequest(item.id)
                                            }
                                          >
                                            <i className="fas fa-trash" />
                                          </Link>
                                          <Link
                                            to={`edit-request/${item.id}`}
                                            className="favourite edit-btn"
                                          >
                                            <i className="fas fa-edit" />
                                          </Link>
                                          <div className="author-heading">
                                            <div className="profile-img">
                                            <Link
                                                to={`request-details/${item?.id}`}
                                                style={{
                                                  height:"100%",
                                                }}
                                              >
                                                <img
                                                  src={user?.image || Avatar_1}
                                                  alt="author"
                                                  style={{
                                                    width:'100%',
                                                    height:"100%",
                                                    borderRadius:"50%"
                                                  }}
                                                />
                                              </Link>
                                            </div>
                                            <div className="profile-name">
                                              <div className="author-location">
                                                {user?.businessName}{" "}
                                                <i className="fas fa-check-circle text-success verified" />
                                              </div>
                                            </div>
                                            <div className="freelance-info">
                                              {/* <div className="freelance-location mb-1">
                                                <i className="fas fa-clock" />{" "}
                                                Posted 29 min ago
                                              </div> */}
                                              <div className="freelance-location">
                                                <i className="fas fa-map-marker-alt me-1" />
                                                {user?.address?.city},{" "}
                                                {user?.address?.country}
                                              </div>
                                            </div>
                                            <div className="freelance-tags">
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {getCategoryById(
                                                    item?.categoryId
                                                  )}
                                                </span>
                                              </Link>
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {subCategories[
                                                    item.subCategoryId
                                                  ] || "Loading..."}
                                                </span>
                                              </Link>
                                            </div>
                                            <Link
                                              to={`request-details/${item?.id}`}
                                              className="freelancers-price"
                                            >
                                              {item?.title}
                                            </Link>
                                          </div>
                                          <div className="counter-stats">
                                            <ul>
                                              <li>
                                                <h3 className="counter-value">
                                                  {getDateTime.getDate(
                                                    item?.createdDate
                                                  )}
                                                </h3>
                                                <h5>{t('userDetails.createdAt')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  {item?.comments?.length}
                                                </h3>
                                                <h5>{t('userDetails.proposals')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  <span className="jobtype">
                                                    Open
                                                  </span>
                                                </h3>
                                                <h5>{t('userDetails.status')}</h5>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                        {/* / MyRequests Tab Content */}
                        {/* MyRequestResponses Tab Content */}
                        <div
                          role="tabpanel"
                          id="saved_jobs"
                          className="tab-pane fade"
                        >
                          <div className="row">
                            {responseTechRequest &&
                              responseTechRequest?.map((item) => {
                                const fetchedUser =
                                  userCache[item.userId] || {};
                                return (
                                  <>
                                    <div className="col-md-6 col-lg-12 col-xl-6">
                                      <div className="freelance-widget widget-author">
                                        <div className="freelance-content">
                                          <div className="author-heading">
                                            <div className="profile-img">
                                              <Link
                                                to={`request-details/${item?.id}`}
                                                style={{
                                                  height: "100%",
                                                }}
                                              >
                                                <img
                                                  className="img-fluid"
                                                  src={
                                                    fetchedUser?.image ||
                                                    Avatar_1
                                                  }
                                                  alt="author"
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </Link>
                                            </div>
                                            <div className="profile-name">
                                              <div className="author-location">
                                                {fetchedUser?.businessName}{" "}
                                                <i className="fas fa-check-circle text-success verified" />
                                              </div>
                                            </div>
                                            <div className="freelance-info">
                                              {/* <div className="freelance-location mb-1">
                                        <i className="fas fa-clock" /> Posted 29
                                        min ago
                                      </div> */}
                                              <div className="freelance-location">
                                                <i className="fas fa-map-marker-alt me-1" />
                                                {fetchedUser?.address?.city},{" "}
                                                {fetchedUser?.address?.country}
                                              </div>
                                            </div>
                                            <div className="freelance-tags">
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {getCategoryById(
                                                    item?.categoryId
                                                  )}
                                                </span>
                                              </Link>
                                              <Link to="#">
                                                <span className="badge badge-pill badge-design">
                                                  {subCategories[
                                                    item.subCategoryId
                                                  ] || "Loading..."}
                                                </span>
                                              </Link>
                                            </div>
                                            <Link
                                              to={`request-details/${item?.id}`}
                                              className="freelancers-price"
                                            >
                                              {item?.title}
                                            </Link>
                                          </div>
                                          <div className="counter-stats">
                                            <ul>
                                              <li>
                                                <h3 className="counter-value">
                                                  {getDateTime.getDate(
                                                    item?.createdDate
                                                  )}
                                                </h3>
                                                <h5>{t('userDetails.createdAt')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  {item?.comments?.length}
                                                </h3>
                                                <h5>{t('userDetails.proposals')}</h5>
                                              </li>
                                              <li>
                                                <h3 className="counter-value">
                                                  <span className="jobtype">
                                                    Open
                                                  </span>
                                                </h3>
                                                <h5>{t('userDetails.status')}</h5>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="cart-hover">
                                          <Link
                                            to={`request-details/${item?.id}`}
                                            className="btn-cart"
                                            tabIndex={-1}
                                          >
                                            {t('userDetails.view')}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                        {/* MyRequestResponses Tab Content */}
                      </div>
                    </div>
                  </div>
                  {/* /Requests Tab Content */}
                </div>
              </div>
            </div>
            {/* Blog Sidebar */}
            <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                {/* Verifications */}
                <div className="pro-post widget-box about-widget">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="pro-title">{t('userDetails.verifications.title')}</h4>
                    </div>
                  </div>
                  <ul className="latest-posts pro-content">
                    <li className="border-bottom-0">
                      <span>
                        <i className="fas fa-check-circle text-success me-4 f-20" />
                      </span>
                      <span>
                        <b>{t('userDetails.verifications.identityVerified')}</b>
                      </span>
                      <span className="float-end text-success">{t('userDetails.verifications.verifyNow')}</span>
                    </li>
                    <li className="border-bottom-0">
                      <span>
                        <i className="fas fa-check-circle text-success me-4 f-20" />
                      </span>
                      <span>
                        <b>{t('userDetails.verifications.paymentVerified')}</b>
                      </span>
                      <span className="float-end text-success">{t('userDetails.verifications.verifyNow')}</span>
                    </li>
                    <li className="border-bottom-0">
                      <span>
                        <i className="fas fa-check-circle text-success me-4 f-20" />
                      </span>
                      <span>
                        <b>{t('userDetails.verifications.phoneVerified')}</b>
                      </span>
                      <span className="float-end text-success">{t('userDetails.verifications.verifyNow')}</span>
                    </li>
                    <li className="border-bottom-0">
                      <span>
                        <i className="fas fa-times-circle text-danger me-4 f-20" />
                      </span>
                      <span>
                        <b>{t('userDetails.verifications.emailVerified')}</b>
                      </span>
                      <span className="float-end text-danger">{t('userDetails.verifications.verifyNow')}</span>
                    </li>
                  </ul>
                </div>
                {/* /Verifications */}
                {/* Follow Widget */}
                {/* <div className="pro-post">
                      <div className="follow-widget">
                        <div className="text-end custom-edit-btn">
                          <Link
                            to="/freelancer-profile-settings"
                            className="sub-title"
                          >
                            <i className="fa fa-pencil-alt me-1" />
                          </Link>
                        </div>
                        <Link to="#" className="btn follow-btn">
                          + Follow
                        </Link>
                        <ul className="follow-posts pro-post">
                          <li>
                            <p>Following</p>
                            <h6>49</h6>
                          </li>
                          <li>
                            <p>Followers</p>
                            <h6>422</h6>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                {/* /Follow Widget */}
                {/* Language Widget */}
                {/* <div className="pro-post widget-box language-widget">
                      <div className="row">
                        <div className="col-10">
                          <h4 className="pro-title mb-0">Language Skills</h4>
                        </div>
                        <div className="col-2 text-end">
                          <Link
                            to="/freelancer-profile-settings"
                            className="sub-title"
                          >
                            <i className="fa fa-pencil-alt me-1" />
                          </Link>
                        </div>
                      </div>
                      <ul className="latest-posts pro-content">
                        <li>
                          <p>English</p>
                          <div className="progress progress-md mb-0">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "50%" }}
                              aria-valuenow={75}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                        <li>
                          <p>Russian</p>
                          <div className="progress progress-md mb-0">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "65%" }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                        <li>
                          <p>German</p>
                          <div className="progress progress-md mb-0">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "50%" }}
                              aria-valuenow={75}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </li>
                      </ul>
                    </div> */}
                {/* /Language Widget */}
                {/* About Widget */}
                <div className="pro-post widget-box about-widget">
                  <div className="row">
                    <div className="col-10">
                      <h4 className="pro-title mb-0">{t('userDetails.aboutMe')}</h4>
                    </div>
                    <div className="col-2 text-end">
                      <Link to="/settings" className="sub-title">
                        <i className="fa fa-pencil-alt me-1" />
                      </Link>
                    </div>
                  </div>
                  <ul className="latest-posts pro-content pt-0">
                    <li>
                      <p>{t('userDetails.email')}</p>
                      <h6>{user?.email}</h6>
                    </li>
                    <li>
                      <p>{t('userDetails.mobile')}</p>
                      <h6>{user?.phoneNumber}</h6>
                    </li>
                    {/* <li>
                      <p>Joined in</p>
                      <h6>20-08-2024</h6>
                    </li> */}
                    <li>
                      <p>{t('userDetails.location')}</p>
                      <h6>
                        {user?.address?.street} {user?.address?.state},{" "}
                        {user?.address?.postCode} {user?.address?.city},{" "}
                        {user?.address?.country}
                      </h6>
                    </li>
                  </ul>
                </div>
                {/* /About Widget */}
                {/* Categories */}
                <div className="pro-post category-widget">
                  <div className="widget-title-box">
                    <div className="row">
                      <div className="col-10">
                        <h3 className="pro-title">{t('userDetails.socialLinks')}</h3>
                      </div>
                      <div className="col-2 text-end">
                        <Link to="/settings" className="sub-title">
                          <i className="fa fa-pencil-alt me-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <ul className="latest-posts pro-content">
                    <li>
                      <Link to="#">http://www.facebook.com/tech...</Link>
                    </li>
                    <li>
                      <Link to="#">http://www.Twitter.com/tech...</Link>
                    </li>
                    <li>
                      <Link to="#">Http://www.instagram.com/tech... </Link>
                    </li>
                    <li>
                      <Link to="#"> Http://www.linkedin.com/tech...</Link>
                    </li>
                    <li>
                      <Link to="#"> Http://www.telegram.com/tech...</Link>
                    </li>
                  </ul>
                </div>
                {/* /Categories */}
                {/* LInk Widget */}
                <div className="pro-post widget-box post-widget">
                  <div className="row">
                    <div className="col-10">
                      <h3 className="pro-title">{t('userDetails.profileLink')}</h3>
                    </div>
                    <div className="col-2 text-end">
                      <Link to="/settings" className="sub-title">
                        <i className="fa fa-pencil-alt me-1" />
                      </Link>
                    </div>
                  </div>
                  <div className="pro-content">
                    <div className="form-group profile-group mb-0">
                      <div className="input-group">
                        <input
                          type="text"
                          readOnly
                          ref={inputRef}
                          className="form-control"
                          defaultValue={`https://www.techforshare.com/company-details/${user?.id}`}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-success sub-btn"
                            type="button"
                            onClick={copyToClipboard}
                          >
                            <i className="fa fa-clone" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Link Widget */}
                {/* Share Widget */}
                {/* <div className="pro-post widget-box post-widget">
                      <h3 className="pro-title">Share</h3>
                      <div className="pro-content">
                        <Link to="#" className="share-icon">
                          <i className="fas fa-share-alt" /> Share
                        </Link>
                      </div>
                    </div> */}
                {/* /Share Widget */}
              </StickyBox>
            </div>
            {/* /Blog Sidebar */}
          </div>
        </div>
      </div>
      {/* /Page Content */}

    </>
  );
};
export default UserDetails;
 