/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Document_Img } from "../../imagepath";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import TechOffers from "../../../../Apis/TechOffersApi";
import LoadingSpinner from "../../../../LoadingSpinner";
import { useHistory, useParams } from "react-router-dom";
import { useCategory } from "../../Category";
import { useSubCategory } from "../../SubCategoryContext";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

const EditProject = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let params = useParams();
  const [techOffer, setTechOffer] = useState();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const { categories, getCategoryById } = useCategory();
  const { subCategories, fetchSubCategories } = useSubCategory();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [previewImage, setPreviewImage] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [description, setDescription] = useState();
  const [refrenceLink, setRefrenceLink] = useState(null);
  const [price, setPrice] = useState();

  const getTechOfferById = async (id) => {
    setLoading(true);
    const response = await TechOffers.getTechOffer(id);
    if (response.success) {
      setTechOffer(response.data);
    } else {
      toast.error("error while getting tech offer");
    }
    setLoading(false);
  };
  function processDescription(des) { 
    if (des) {
      // Initialize variables for the cleaned description and the reference link
      let cleanedDescription = des;
      let refLink = null;

      // Check if the string contains "Ref:"
      const refIndex = des?.indexOf("Ref:");

      if (refIndex !== -1) {
        // Extract the string before "Ref:" as the cleaned description
        cleanedDescription = des?.substring(0, refIndex)?.trim();

        // Extract the string after "Ref:" as the reference link
        refLink = des?.substring(refIndex + 4)?.trim();
      } 

      return { cleanedDescription, refLink };
    }
  }

  const handleUpdateTechOffer = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("Id", techOffer.id);
    formData.append("CategoryId", category);
    formData.append("SubCategoryId", subCategory);
    formData.append("Title", title);
    formData.append("Price", price);
    formData.append("Description", description);
    imageFile?.forEach((image) => {
      formData.append("Images", image, uuidv4() + ".png");
    });
    if (
      title !== "" &&
      category !== "" &&
      subCategory !== "" &&
      description !== "" &&
      imageFile?.length > 0
    ) {
      let fullDescription = description;
      if (refrenceLink) {
        fullDescription += `\n\nRef: ${refrenceLink}`;
      }
      const response = await TechOffers.updateTechOffer(formData);
      if (response.success) {
        
        toast.success("tech offer updated successfully");
        history.push("/account-details");
      }
    } else {
      toast.error("please fill in all fields");
    }
    setLoading(false)
  };

  useEffect(() => {
    const getSubCategories = async () => {
      if (category && category !== 0) {
        await fetchSubCategories(category);
      }
    };
    getSubCategories();
  }, [category]);

  useEffect(() => {
    document.body.className = "dashboard-page";
    return () => {
      document.body.className = "";
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchImageAsBlob = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return blob;
  };
  
  const convertBlobToFile = (blob, filename) => {
    return new File([blob], filename, { type: blob.type });
  };
  
  const createFormDataFromUrls = async (urls) => {
    const formData = []
  
    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];
      const blob = await fetchImageAsBlob(url);
      const file = convertBlobToFile(blob, `image${i + 1}.png`); 
      formData.push(file); 
    }
  
    return formData;
  };

  useEffect(() => {
    if (techOffer) {
      setTitle(techOffer.title || "");
      setPrice(techOffer?.price || "");
      setCategory(techOffer.categoryId || "");
      setSubCategory(techOffer.subCategoryId || "");
      const result = processDescription(techOffer.description || "");
      setDescription(result?.cleanedDescription || "");
      setRefrenceLink(result?.refLink || "");
      
      (async () => {
        const imageFiles = await createFormDataFromUrls(techOffer.images || []);
        setImageFile(imageFiles);
        setPreviewImage(techOffer.images || []);
      })();
    }
  }, [techOffer]);

  useEffect(() => {
    const fetchData = async () => {
      const isValidUUID = (id) => {
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(id);
      };
      if (params.id && isValidUUID(params.id)) {
        getTechOfferById(params.id);
      } else {
        history.push("/404");
      }
    };
    fetchData();
  }, [params?.id]);

  const handleImageUpload = (files) => {
    const newImages = [...previewImage];
    const newImageFiles = [...imageFile]; 
    const newImagesBob = [];
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const blob = new Blob([file.slice(0, file.size)], { type: file.type });
        newImages.push(e.target.result);
        newImagesBob.push(blob);
        setPreviewImage(newImages);
        setImageFile([...newImageFiles, ...newImagesBob]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (index) => {
    const newImages = [...previewImage];
    const newImagesBob = imageFile;
    newImages.splice(index, 1);
    newImagesBob.splice(index, 1);
    setPreviewImage(newImages);
    setImageFile(newImagesBob);
  }; 

  if (!params.id) {
    history.pushState("/404");
  }
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">{t('sharePost.updatePost')}</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {" "}
                    {t('sharePost.editPost')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-end mb-4">
                <Link to="/account-details" className="btn btn-primary back-btn br-0">
                  <i className="fas fa-long-arrow-alt-left" /> {t('sharePost.backToPosts')}{" "}
                </Link>
              </div>
              <div className="select-project mb-4">
                <form>
                  <div className="title-box widget-box">
                    {/* Project Title */}
                    <div className="title-content">
                      <div className="title-detail">
                        <h3>{t('sharePost.postTitle')}</h3>
                        <div className="form-group mb-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Xiaomi Mi 11 SIM Tray"
                            value={title}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /Project Title */}
                    {/* Category Content */}
                    <div className="title-content">
                      <div className="title-detail">
                        <h3>{t('requestPart.categoryType')}</h3>
                        <div className="form-group mb-0">
                          <select
                            className="form-control select"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value={0}>{t('requestPart.select')}</option>
                            {categories.map((cat) => (
                              <option key={cat.id} value={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* /Category Content */}
                    {/* Price Content */}
                    <div className="title-content">
                      <div className="title-detail">
                        <h3>{t('requestPart.subCategory')}</h3>
                        <div className="form-group mb-0">
                          <select
                            className="form-control select"
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                          >
                            <option value="">{t('requestPart.select')}</option>
                            {subCategories?.map((subCat) => (
                              <option key={subCat.id} value={subCat.id}>
                                {subCat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="title-content">
                      <div className="title-detail">
                        <h3>Price of the part *</h3>
                        <div className="input-group">
                          <div className="btn btn-white disabled">€</div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={20.0}
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* /Price Content */}

                    {/* /Project Period Content */}
                    {/* /Add Document */}
                    <div className="title-content">
                      <div className="title-detail">
                        <h3>{t('requestPart.addImages')}</h3>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            multiple
                            onChange={(e) => handleImageUpload(e.target.files)}
                          />
                          <label className="custom-file-label" />
                        </div>
                        <p>{t('requestPart.imageSizeNotice')}</p>
                        <div className="upload-wrap">
                          {previewImage?.length > 0 &&
                            previewImage?.map((image, ind) => (
                              <>
                                <div className="upload-document">
                                  <img src={image} alt="Image" />
                                  <Link
                                    to="#"
                                    onClick={() => handleDelete(ind)}
                                    className="btn btn-icon btn-danger btn-sm"
                                  >
                                    <i className="material-icons">delete</i>
                                  </Link>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* /Add Document */}
                    {/* Add Links */}
                    <div className="title-content">
                      <div className="title-detail">
                        <h3>{t('requestPart.addLinks')}</h3>
                        <div className="links-info">
                          <div className="row form-row links-cont">
                            <div className="col-12 col-md-11">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={price}
                                  onChange={(e) =>
                                    setPrice(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="col-12 col-md-1">
                            <Link
                              to=""
                              className="btn btn-danger trash"
                            >
                              <i className="far fa-trash-alt" />
                            </Link>
                          </div> */}
                          </div>
                          <div className="row form-row links-cont">
                            {/* <div className="col-12 col-md-11">
                            <div className="form-group">
                              <input type="text" className="form-control" />
                            </div>
                          </div> */}
                            {/* <div className="col-12 col-md-1">
                            <Link
                              to=""
                              className="btn btn-danger trash"
                            >
                              <i className="far fa-trash-alt" />
                            </Link>
                          </div> */}
                            <div className="col-md-6">
                              {/* <p className="mb-0">Add Reference links if any</p> */}
                            </div>
                            {/* <div className="col-md-5 text-end">
                            <Link
                              to=""
                              className="add-links more-link"
                            >
                              <i className="fas fa-plus-circle orange-text" /> Add
                              more
                            </Link>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Add Links */}
                    {/* Project Title */}
                    <div className="title-content pb-0">
                      <div className="title-detail">
                        <h3> {t('requestPart.writeDescription')}</h3>
                        <div
                          className="form-group mb-0"
                          style={{
                            border: " 1px solid rgba(128, 128, 128, 0.36)",
                          }}
                        >
                          <textarea
                            className="form-control summernote"
                            rows={5}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /Project Title */}
                    <div className="text-end">
                      <div className="btn-item">
                        <button
                          className="btn btn-primary black-btn click-btn b-0"
                          type="submit"
                        >
                          {t('requestPart.cancel')}
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary click-btn b-0"
                          type="button"
                          onClick={()=>handleUpdateTechOffer()}
                        >
                          {t('requestPart.submit')}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Project Title */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </>
  );
};
export default EditProject;
